<template>
  <b-navbar-brand href="/dashboard" class="valorem-logo-navbar">
    <b>
      <img
        src="../../../assets/images/logo-valorem.svg"
        class="logo-valorem"
        alt="homepage"
      />
    </b>
  </b-navbar-brand>
</template>

<script>
export default {
  name: "LogoDark",
};
</script>
