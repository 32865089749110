<template>
  <b-modal
    :title="$t('GERAL.TROCAR_SENHA')"
    header-bg-variant="light"
    ref="modal-login"
    size="md"
    hide-footer
    @hidden="fecharModal()"
  >
    <b-card-text>
      
      <input-text
        ref="senha"
        v-model="form.senha"
        noLabel
        type="password"
        :label="$t('GERAL.NOVA_SENHA')"
        :placeholder="$t('GERAL.NOVA_SENHA')"
        :minLength="6"
        required
        class="mb-2"
      />
        
      <verifica-forca-senha embaixo :value="forcaSenha"></verifica-forca-senha>

      <input-text
        class="mt-2"
        ref="confirmacaoSenha"
        v-model="form.confirmacaoSenha"
        noLabel
        type="password"
        :label="$t('GERAL.CONFIRMAR_NOVA_SENHA')"
        :placeholder="$t('GERAL.CONFIRMAR_NOVA_SENHA')"
        :validate="validarSenha"
        :minLength="6"
        required
      />

      <b-button
        variant="primary"
        size="lg"
        block
        class="mt-4"
        @click="alterarSenha"
        >{{ $t("GERAL.TROCAR_SENHA") }}</b-button
      >
    </b-card-text>
  </b-modal>
</template>
<script>
import InputText from "@/components/inputs/InputText";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import UsuarioService from "@/common/services/usuario/usuario.service";
import VerificaForcaSenha from "@/views/usuarios/components/VerificaForcaSenha.vue";
export default {
  name: "ModalAlteraSenha",
  data() {
    return {
      form: {
        senha: null,
        confirmacaoSenha: null
      },
    };
  },
  components: {
    InputText,
    VerificaForcaSenha
  },
  props: {
    mostrarModal: { type: Boolean, default: false },
    forcaSenha: { type: Number, default: 0 },
  },
  watch: {
    mostrarModal(valor) {
      if (valor) this.$refs["modal-login"].show();
      else this.$refs["modal-login"].hide();
    },
    "form.senha"() {
      this.verificarForcaDaSenha();
    },
  },
  computed: {
    
    validarSenha() {
      return this.form.nova_senha === this.form.nova_senha_confirmacao;
    },
  },
  methods: {
    verificarForcaDaSenha() {
      let senha = this.form.senha;
      UsuarioService.verificarForcaDaSenha(senha)
        .then((result) => {
          this.forcaSenha = result.data.forcaSenhaEnum;
        })
        .catch((err) => console.log(err));
    },
    fecharModal() {
      this.$emit("fecharModal");
    },
    validarFormulario() {
      let arValidation = [];
      arValidation.push(this.$refs.senha.valid());
      arValidation.push(this.$refs.confirmacaoSenha.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    alterarSenha() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      this.form.usuarioId = localStorage.getItem('usuario_id');
      UsuarioService.alterarSenha(this.form)
        .then(() => {
          this.fecharModal();
        })
        . catch(() => {
          
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
