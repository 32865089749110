<template>
  <div  :class="`${LayoutType} ${Theme} page-${this.$route.name}`">
    <VerticalSidebar v-if="!setHorizontalLayout" />
    
    <aside :class="setHorizontalLayout == true ? 'horizontal-page-wrapper' : 'page-wrapper'">
      
      
      <VerticalHeader v-if="!setHorizontalLayout" />
      <HorizontalHeader v-if="setHorizontalLayout" />
      <HorizontalSidebar v-if="setHorizontalLayout" />



        <div class="content-area">
          <div class="min-height"  :class="setHorizontalLayout == true ? 'container-fluid p-4' : 'container-fluid p-4'">
          
            <h4 class="page-title" v-if="tituloPagina && rota">{{ tituloPagina }}</h4>
            <b-breadcrumb class="p-0" v-if="rota">
              <b-breadcrumb-item :href="$route.name != 'acesso-sacado' ? '/dashboard' : ''" custom> Home </b-breadcrumb-item>
              <b-breadcrumb-item v-for="breadcrumb in breadcrumbs" :key="breadcrumb.titulo" active>{{ breadcrumb.titulo }}</b-breadcrumb-item>
            </b-breadcrumb>
            <router-view />
          </div>
        </div>
      
    </aside>
  </div>
</template>

<script>
import VerticalHeader from "./vertical-header/VerticalHeader";
import HorizontalHeader from "./horizontal-header/HorizontalHeader";
import HorizontalSidebar from "./horizontal-sidebar/HorizontalSidebar";
import VerticalSidebar from "./vertical-sidebar/VerticalSidebar";
import { mapState, mapGetters } from "vuex";
export default {
  name: "FullLayout",
  components: {
    VerticalHeader,
    VerticalSidebar,
    HorizontalSidebar,
    HorizontalHeader,
  },
  data() {
    return {
      verAnuncio: localStorage.getItem("ver-anuncio")  ? localStorage.getItem("ver-anuncio") === "true" : true,
      textoBtnAnuncio: localStorage.getItem("ver-anuncio") === "true" ? "Esconder Anúncio" : "Mostrar Anúncio"
    };
  },
  computed: {
    ...mapState(["LayoutType", "Theme", "setHorizontalLayout"]),
    ...mapGetters(["breadcrumbs", "tituloPagina"]),
    currentRouteName() {
      return this.$route.name;
    },
    rota() {
      return this.$route.path !== "/dashboard";
    },
  },
  methods: {
    alterarVerAnuncio(){
      let verAnuncio = this.verAnuncio;

      verAnuncio = !verAnuncio;
      this.verAnuncio = verAnuncio;

      this.textoBtnAnuncio = verAnuncio == true ? "Esconder Anúncio" : "Mostrar Anúncio"

      localStorage.setItem("ver-anuncio", verAnuncio);
    },
    winWidth: function () {
      setInterval(() => {
        var w = window.innerWidth;

        if (w < 992) {
          this.$store.commit("SET_LAYOUT_TYPE", "mini-sidebar");
        }
      }, 100);
    },
  },
  mounted() {
    this.winWidth();
  },
  watch: {},
};
</script>

<style>
  .horizontal-page-wrapper{
    position: relative;
  }
  .content-page{
    display: grid; 
    grid-template-columns: 15% 70% 15%; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "mensageria-left content-area mensageria-right";
  }
  .mensageria-left { 
    grid-area: mensageria-left; 
    padding: 20px;
    padding-top: 30px;
  }
  .mensageria-right { 
    grid-area: mensageria-right;
    padding: 20px;
    padding-top: 30px;
  }
  .content-area { grid-area: content-area; }

  .btn-esconder-anuncio{
    position: fixed;
    bottom: 25px;
    left: 20px;
    padding: 15px;
    cursor: pointer;
    transition: 0.5s;
    background: #033453;
    color: white;
  }

  .btn-esconder-anuncio:hover {
    transform: scale(1.1);
  }
</style>
