<template>
  <b-navbar-brand href="/dashboard">
    <b class="logo-icon">
      <img src="../../../assets/images/logo-light-icon.png" alt="homepage" />
    </b>
    <span class="logo-text">
      <img src="../../../assets/images/logo-light-text.png" alt="homepage" />
    </span>
  </b-navbar-brand>
</template>

<script>
export default {
  name: "LogoLight",
};
</script>
