<template>
  <header class="topbar">
    <b-navbar toggleable="lg" :type="`${navbarColor == 'primary' ? 'light' : 'dark'}`" class="p-0"
      :variant="navbarColor">
      <div class="container-fluid">
        <b-navbar-nav>
          <b-nav-item @click="showMobileSidebar" class="d-block d-lg-none"><i class="mdi mdi-menu fs-3"></i>
          </b-nav-item>
        </b-navbar-nav>
        <div class="mx-2 logo-top">
          <LogoDark v-if="navbarColor == 'primary'" />
          <LogoLight v-else />

          <span v-if="verificarEnv()" class="badge bg-danger homolog-alert"> {{ $t("GERAL.HOMOLOG") }}</span>

        </div>
        <b-navbar-toggle target="nav-collapse" class="mx-2"><i class="ti-more"></i></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav class="">
          <!-- Right aligned nav items -->

          <b-navbar-nav class="ml-auto align-auto">
            <a class="perguntas" href="https://www.valorem.com.br/perguntas-frequentes-portal/" target="_blank">
              <feather type="help-circle"></feather>
              <span class="faq">
                {{ $t("MENU.FAQ") }}
              </span>
            </a>
            <div class="mx-2">
              <span class="d-none d-md-block mt-2 font-weight-bold fa-inverse">{{ nomeUsuario }}</span>
              <a @click="abrirMinhaConta()" v-if="rotaAtual"
                class="cursor-pointer d-none d-md-block fa-sm fa-inverse fa-pull-right">{{ $t("GERAL.MINHA_CONTA")
                }}</a>
            </div>
            <!-- -----------------------------------------------------------
            Profile Dropdown
          ----------------------------------------------------------- -->
            <b-nav-item-dropdown right no-caret class="mailbox">
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <img src="../../../assets/images/users/1.jpg" alt="user" class="rounded-circle" width="31" />
              </template>
              <b-dropdown-text class="bg-primary text-white">
                <div class="d-flex align-items-center py-2">
                  <b-img src="@/assets/images/users/1.jpg" width="50" rounded="circle" />
                  <div class="ml-3">
                    <h4 class="mb-0">{{ nomeUsuario }}</h4>
                    <span class="font-weight-light">{{ email }}</span>
                  </div>
                </div>
              </b-dropdown-text>

              <b-dropdown-item href="#" v-if="rotaAtual" class="d-flex align-items-center" id="show-modal"
                @click="abrirModal">
                <feather type="lock" class="feather-sm mr-2 mb-n1"></feather>
                {{ $t("GERAL.TROCAR_SENHA") }}
              </b-dropdown-item>

              <b-dropdown-item href="#" v-if="!rotaAtual" class="d-flex align-items-center" @click="logoutSacado">
                <feather type="log-out" class="feather-sm mr-2 mb-n1"></feather>
                {{ $t("GERAL.SAIR") }}
              </b-dropdown-item>
              <b-dropdown-item href="#" class="d-flex align-items-center" v-else @click="logout">
                <feather type="log-out" class="feather-sm mr-2 mb-n1"></feather>
                {{ $t("GERAL.SAIR") }}
              </b-dropdown-item>

            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
    <modal-altera-senha :mostrarModal="mostrarModal" @fecharModal="fecharModal" />
  </header>
</template>

<script>
import { mapState } from "vuex";
import LogoDark from "../logo-dark/LogoDark";
import LogoLight from "../logo-light/LogoLight";
import { LOGOUT, LOGOUT_SACADO } from "@/store/autenticacao.module";
import { DEFINIR_IDIOMA } from "@/store/idioma.module";
import JwtService from "@/common/utils/jwt";
import { START_LOADING } from "@/store/Store";
import Swal from "sweetalert2";
import ModalAlteraSenha from "@/layouts/components/ModalAlteraSenha";
export default {
  name: "Horizontal-header",
  data: () => ({
    showToggle: false,
    mostrarModal: false,
  }),
  components: {
    LogoDark,
    LogoLight,
    ModalAlteraSenha,
  },
  computed: {
    ...mapState(["navbarColor", "logoColor", "LayoutType"]),
    getCurrentLanguage() {
      const locale = this.$i18n.locale;
      if (locale == "en") return { lang: "flag-icon flag-icon-us" };
      else if (locale == "br") return { lang: "flag-icon flag-icon-br" };
      return this.locale;
    },
    nomeUsuario() {
      return `${JwtService.buscarNome()}`;
    },
    email() {
      return JwtService.buscarEmail();
    },
    usuarioId(){
      return JwtService.buscarId();
    },
    rotaAtual(){
      return  this.$router.currentRoute.name != "acesso-sacado";
    }
  },
  methods: {
    verificarEnv(){
      return process.env.VUE_APP_API.indexOf('homolog') > -1;
    },
    showMobileSidebar() {
      this.$store.commit("SET_SIDEBAR_DRAWER", true);
    },
    setMiniSidebar() {
      if (this.LayoutType == "full-sidebar") {
        this.$store.dispatch("setLayoutType", "mini-sidebar");
      } else if (this.LayoutType == "mini-sidebar") {
        this.$store.dispatch("setLayoutType", "full-sidebar");
      }
    },
    alterarIdioma(idioma) {
      this.$store.dispatch(START_LOADING);
      this.$store.dispatch(DEFINIR_IDIOMA, idioma).then(() => {
        location.reload();
      });
      this.$i18n.locale = idioma;
    },
    logout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "Login" }))
        .catch((err) => {
          Swal.fire({
            title: this.$t("GERAL.ERRO"),
            text: err,
            icon: "error",
          });
        });
    },
    logoutSacado() {
      this.$store
        .dispatch(LOGOUT_SACADO)
        .then(() => this.$router.push({ name: "Login" }))
        .catch((err) => {
          Swal.fire({
            title: this.$t("GERAL.ERRO"),
            text: err,
            icon: "error",
          });
        });
    },
    abrirModal() {
      this.mostrarModal = true;
    },
    fecharModal() {
      this.mostrarModal = false;
    },
    abrirMinhaConta(){
      this.$router.push({ name: "minha-conta" });
    }
  },
};
</script>

<style>


  .homolog-alert{
    color: white!important;
    margin-left: 10px;
  }

  .logo-top{
    display: flex;
    align-items: center;
  }

  .perguntas {
      color: white;
      display: grid;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 47px;
  }

  .align-auto{
    display: flexa;
    align-items: center;
  }

  .perguntas:hover{
    color: #fd7e14
  }

  .perguntas i {
    margin: auto;
  }

  .faq{
    font-size: 0.875em;
  }
</style>